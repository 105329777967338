<template>
  <div>
    <b-row class="rowContainer">
      <b-col md="2">  

      </b-col>
      <b-col md="8">  
        <b-row class="justify-content-center align-items-center">
          <b-carousel
            id="carouselId"
            ref="refCarousel"
            class="carouselClass"
            :interval="50000"
            controls
            indicators
            fade
          >
            <div 
            v-for="(projectInfo, index) in projectArr" 
            :key="index"
            >
              <b-carousel-slide
                class="img-fluid"
                @click="handleClick(projectInfo)"
              >
                <h3>
                  <span class="text-white">{{projectInfo.title}}</span>
                </h3>
                <p>{{projectInfo.content}}</p> 
                <template #img>
                    <img
                      class="d-block img-fluid w-100"
                      :src="require(`@/assets/images/slider/${projectInfo.image}`)"
                      alt="image slot"
                    >
                </template>
              </b-carousel-slide>
            </div>
          </b-carousel>
        </b-row>
        <b-row class="justify-content-center align-items-center">
          <div class="d-flex align-items-center justify-content-center mt-1 ">
            <b-button-group>
              <div 
                v-for="(projectInfo, index) in projectArr" 
                :key="index"
                class="button-margin"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                   size="lg"
                  @click="goToSlide(projectInfo, index)"
                >
                  {{projectInfo.title}} 
                </b-button>
              </div>
            </b-button-group>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCarousel, 
  BCarouselSlide, 
  BButtonGroup, 
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
    BButtonGroup,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    status: false,
    projectArr: [
      {
        title: 'Tunnel',
        content: 'Tunnel Content',
        image: 'selectProject_tunnel.png',
        // url: 'http://10.1.3.65:34050/#/dashboard'
        url: 'http://10.1.3.68:34050/#/dashboard'
      },
      {
        title: 'Highway',
        content: 'Highway Content',
        image: 'selectProject_highway.png',
        url: 'http://10.1.3.68:34030/#/dashboard'
      },
      {
        title: 'Bridge',
        content: 'Bridge Content',
        image: 'selectProject_bridge.png',
        url: 'http://10.1.3.149:34040/'
      },
    ],
  }),
  methods: {
    goToUrl(url){
       window.location.href = url;
    },
    goToSlide(projectInfo, projectIndex ) {
      this.$refs.refCarousel.setSlide(projectIndex);
      // window.location.href = slideInfo.url;
      window.open(projectInfo.url, '_blank');
    },
    prev() {
      if (!this.status) {
        this.$refs.refCarousel.prev()
      }
    },
    next() {
      if (!this.status) {
        this.$refs.refCarousel.next()
      }
    },
    start() {
      this.status = !this.status
      if (this.status) {
        this.$refs.refCarousel.pause()
      } else {
        this.$refs.refCarousel.start()
      }
    },
  },
  created(){
  },
}
</script>

<style scoped>
.img-fluid {
  max-width: 100%;
  width :auto;
  height: auto;
  justify-content: center;
  align-items: center;
  border-radius: 20px; /* 둥근 모서리 설정 */
  overflow: hidden; /* 둥근 모서리 밖으로 내용이 넘치지 않도록 설정 */
}
.carouselClass{
 text-align: center;
}
.text-color{
  color : orange
}
.button-margin{
  margin-left: 5px;
  margin-right: 5px;
}
.button-margin .btn{
  width : 128px;
  height: 48px;
}
#carouselId >>> .carousel-control-prev{
  left : -10%;
}
#carouselId >>> .carousel-control-next{
  right : -10%;
}
#carouselId >>> .carousel-control-prev-icon,
#carouselId >>> .carousel-control-next-icon {
  background-color: gray; 
  border-radius: 20px; /* 둥근 모서리 설정 */

  /* background-color: lightgray;  */
}

</style>